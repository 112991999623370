import './Login.css';
import logo from '../../img/logo.svg';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
//import express from 'express';

const Login = () => {
    const [loginId, setLoginId] = useState('');
    const [loginPw, setLoginPw] = useState('');
    const [loginHold, setLoginHold] = useState(false);
    const navigate = useNavigate();
    console.log(loginHold);

    /*
    "username": "username",
    "password": "password"
    */
    const fetchLoginData = async () => {
        try {
            const response = await axios.post('http://co.mayeon.kr:8081/user/login', {
                username: loginId,
                password: loginPw,
            });
            console.log(response.data);
            if (loginHold) {
                localStorage.setItem('token', response.data.userId);
                localStorage.setItem('userId', response.data.userId);
                localStorage.setItem('username', response.data.name);
            } else {
                sessionStorage.setItem('token', response.data.userId);
                sessionStorage.setItem('userId', response.data.userId);
                sessionStorage.setItem('username', response.data.name);
            }

            window.location.replace('/main');
        } catch (error) {
            localStorage.clear();
            sessionStorage.clear();
            console.error('Error fetching login data:', error);
        }
    };

    const handleLogin = () => {
        fetchLoginData();
    };

    const handleCP = () => {
        navigate('/createpeople');
    };
    const handleMain = () => {
        navigate('/main');
    };
    const handlePosview = () => {
        navigate('/serchposterpage');
    };
    const handleMusview = () => {
        navigate('/serchmuseumpage');
    };
    const handleCommuview = () => {
        navigate('/communitymainpage');
    };

    const handelCheck = ({ target }) => {
        setLoginHold(!loginHold);
    };

    return !localStorage.getItem('token') ? (
        <div className="loginframe">
            <div className="loginpagelogininputform">
                <div className="loginpageformbg" />
                <div className="loginpageidbutton">
                    <div className="loginpageidbuttonframe">
                        <input
                            type="text"
                            className="loginpageidbuttontxt"
                            placeholder="ID"
                            value={loginId}
                            onChange={(e) => setLoginId(e.target.value)}
                        />
                    </div>
                </div>
                <div className="loginpagepwbutton">
                    <div className="loginpageidbuttonframe">
                        <input
                            type="password"
                            className="loginpageidbuttontxt"
                            placeholder="PW"
                            value={loginPw}
                            onChange={(e) => setLoginPw(e.target.value)}
                        />
                    </div>
                </div>
                <div className="loginpageloginbutton" onClick={handleLogin}>
                    <div className="loginpageidbuttonframe">
                        <div className="loginpageloginbuttontxt">로그인</div>
                    </div>
                </div>
                <div className="logininputgroup">
                    {/* <div className="forgotpassword">비밀번호를 잊으셨나요?</div> */}
                    <div className="loginpagecheck">
                        <div className="loginpagerememberid">아이디 기억하기</div>
                        <input className="loginpagecheckboxshape" type="checkbox" onChange={handelCheck} />
                    </div>
                </div>

                <div className="loginpageregistertxt">
                    <span className="loginpageregistertxt-txt">
                        <span>계정이 없으신가요? </span>
                        <span className="span" onClick={handleCP}>
                            회원가입
                        </span>
                    </span>
                </div>
            </div>
            <img className="loginmainlogo-icon" alt="" src={logo} />
            <div className="navbar">
                <div className="navlink">
                    <div className="navlinkhome" onClick={handleMain}>
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkPosview" onClick={handlePosview}>
                        <div className="Posviewhome">공연 기반 정보</div>
                    </div>
                    <div className="navlinkMusview" onClick={handleMusview}>
                        <div className="Musviewhome">공연장 기반 정보</div>
                    </div>
                    <div className="navlinkCommuview" onClick={handleCommuview}>
                        <div className="Commuhome">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        window.location.replace('/main')
    );
};

export default Login;
