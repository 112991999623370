/* global kakao */
import './SerchDetailMapPage.css';
import pinsleep from '../../img/pinsleep.png';
import pinrestaurant from '../../img/pinrestaurant.png';
import navback from '../../img/navback.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import NavHeader from '../../components/header';

const SerchDetailMapPage = () => {
    const { performanceId } = useParams();
    const [performanceData, setPerformanceData] = useState(null); // 공연 상세 정보
    const [amenities, setAmenities] = useState([]); // 편의시설 정보
    const mapContainer = useRef(null);

    useEffect(() => {
        const fetchPerformanceData = async () => {
            try {
                const response = await fetch(`http://co.mayeon.kr:8081/culture/performance/detail/${performanceId}`);
                const data = await response.json();
                setPerformanceData(data.culture || {}); // 공연 상세 정보
                setAmenities(data.amenityList || []); // 편의시설 정보
            } catch (error) {
                console.error('Error fetching performance data:', error);
            }
        };

        fetchPerformanceData();
    }, [performanceId]);

    // Kakao 지도 초기화
    useEffect(() => {
        if (!amenities || amenities.length === 0) return;

        const mapOptions = {
            center: new window.kakao.maps.LatLng(amenities[0].mapy, amenities[0].mapx),
            level: 4,
        };

        const map = new window.kakao.maps.Map(mapContainer.current, mapOptions);

        amenities.forEach((amenity) => {
            if (amenity.mapy && amenity.mapx) {
                const icon = amenity.contenttypeid === 32 ? pinsleep : pinrestaurant;

                const markerPosition = new window.kakao.maps.LatLng(amenity.mapy, amenity.mapx);

                const marker = new window.kakao.maps.Marker({
                    position: markerPosition,
                    image: new window.kakao.maps.MarkerImage(icon, new window.kakao.maps.Size(30, 45)),
                    map: map,
                });

                const infowindow = new window.kakao.maps.InfoWindow({
                    content: `<div style="padding:5px;font-size:14px;">
                                <b>${amenity.title}</b><br />
                                ${amenity.addr1 || '주소 없음'}
                              </div>`,
                });

                window.kakao.maps.event.addListener(marker, 'mouseover', () => {
                    infowindow.open(map, marker);
                });

                window.kakao.maps.event.addListener(marker, 'mouseout', () => {
                    infowindow.close();
                });
            }
        });
    }, [amenities]);

    if (!performanceData) {
        return <div>공연 정보를 로딩 중입니다...</div>;
    }

    return (
        <div className="serdetmapmainframe">
            {/* 공연 상세 정보 */}
            <div className="serdetmapsubframe">
                <img className="serdetmapdesimg-icon" alt="poster" src={performanceData.poster || navback} />
                <div className="serdetmapdesframe">
                    <b className="serdetmaptitletxt">{performanceData.prfnm || '공연명 없음'}</b>
                    <div className="serdetmapperiodtxt">{`기간: ${performanceData.prfpdfrom || '정보 없음'} - ${
                        performanceData.prfpdto || '정보 없음'
                    }`}</div>
                    <div className="serdetmapdestxt">{`위치: ${performanceData.fcltynm || '위치 정보 없음'}`}</div>
                    <div className="serdetmapgenretxt">{`장르: ${performanceData.genrenm || '장르 정보 없음'}`}</div>
                    <div className="serdetmapscaletxt">{`규모: ${performanceData.as || '정보 없음'}`}</div>
                    <div className="serdetmappnumtxt">{`전화: ${performanceData.telno || '전화 정보 없음'}`}</div>
                    <div className="serdetmapruntimetxt">{`공연 런타임: ${
                        performanceData.prfruntime || '정보 없음'
                    }`}</div>
                    <div className="serdetmapagetxt">{`관람 연령: ${performanceData.prfage || '정보 없음'}`}</div>
                    <div className="serdetmappricetxt">{`티켓가격: ${
                        performanceData.pcseguidance || '정보 없음'
                    }`}</div>
                    <div className="serdetmapstorytxt">{`줄거리: ${performanceData.story || '줄거리 정보 없음'}`}</div>
                    <div className="serdetmapdesalign" />
                </div>
            </div>

            {/* 지도 */}
            <div className="serdetmapframe">
                <div
                    ref={mapContainer}
                    style={{
                        width: '89.125rem',
                        height: '52.188rem',
                        borderRadius: '10px',
                    }}
                ></div>
            </div>

            <NavHeader />
        </div>
    );
};

export default SerchDetailMapPage;
