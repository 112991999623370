import './MyListEditPage.css';
import close from '../../img/close.svg';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import NavHeader from '../../components/header';

const MyListEditPage = () => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [deletedItems, setDeletedItems] = useState([]);
    const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');

    useEffect(() => {
        const fetchList = async () => {
            try {
                const response = await fetch(`http://co.mayeon.kr:8081/stored-culture/${userId}`);
                const data = await response.json();
                setList(data);
            } catch (error) {
                console.error('Error fetching the list', error);
            }
        };
        fetchList();
    }, [userId]);

    const handleDelete = (id) => {
        setDeletedItems([...deletedItems, id]);
        setList(list.filter((item) => item.mt20id !== id));
    };

    const handleSaveChanges = async () => {
        console.log(deletedItems);

        try {
            const response = await fetch(`http://co.mayeon.kr:8081/stored-culture/${userId}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    performanceIds: deletedItems,
                }),
            });
            if (response.ok) {
                alert('목록 수정이 완료되었습니다.');
                navigate('/mylistpage');
            } else {
                console.error('Error saving changes', response.statusText);
            }
        } catch (error) {
            console.error('Error saving changes', error);
        }
    };

    const handleCancel = () => {
        navigate('/mylistpage');
    };

    const handleDetails = (id) => {
        navigate(`/serchdetailpage/${id}`);
    };

    return (
        <div className="mylisteditframe">
            <div className="mylisteditbuttonframe">
                <div className="mylisteditcompletebutton" onClick={handleSaveChanges}>
                    <div className="mylisteditcompletebuttonframe">
                        <b className="mylisteditcompletebuttontxt">목록 수정완료</b>
                    </div>
                </div>
                <div className="mylisteditcancelbutton" onClick={handleCancel}>
                    <div className="mylisteditcompletebuttonframe">
                        <b className="mylisteditcompletebuttontxt">목록 수정취소</b>
                    </div>
                </div>
            </div>
            <div className="mylistedit-container">
                {list.map((item) => (
                    <div key={item.mt20id} className="mylisteditframe1">
                        <img
                            className="close-icon"
                            alt="delete"
                            src={close}
                            onClick={() => handleDelete(item.mt20id)}
                        />
                        <img className="mylisteditframeimg1-icon" alt={item.prfnm} src={item.poster} />
                        <div className="mylisteditframetxt1">{item.prfnm}</div>
                        <div className="mylisteditframe1button" onClick={() => handleDetails(item.mt20id)}>
                            <div className="mylisteditcompletebuttonframe">
                                <b className="mylisteditcompletebuttontxt">상세보기</b>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <NavHeader />
        </div>
    );
};

export default MyListEditPage;
