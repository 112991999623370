import './CreatePeople.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreatePeople = () => {
    const [cpId, setcpId] = useState('');
    const [cpPw, setcpPw] = useState('');
    const [cpPwCheck, setcpPwCheck] = useState('');
    const [cpName, setcpName] = useState('');
    const [cpBDate, setcpBDate] = useState('');
    const [cpPNum, setcpPNum] = useState('');
    const navigate = useNavigate();

    /*
    "username": "username1",
    "password": "password",
    "birthDate": "2024-09-30",
    "phoneNum": "010-0000-0000",
    "name": "name"
    */

    const fetchCPData = async () => {
        if (cpPw !== cpPwCheck) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }
        try {
            const response = await fetch('http://co.mayeon.kr:8081/user/new', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: cpId,
                    password: cpPw,
                    name: cpName,
                    birthDate: cpBDate,
                    phoneNum: cpPNum,
                }),
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            //const data = await response.json();
            alert('회원가입이 완료되었습니다.');
            navigate('/createpeopleafter'); // 회원가입 후 로그인 페이지로 이동
        } catch (error) {
            console.error('Error during registration:', error);
            alert('회원가입 중 오류가 발생했습니다.');
        }
    };

    const cpSubmit = () => {
        if (window.confirm('위 내용으로 회원가입하시겠습니까?')) {
            fetchCPData();
        }
    };
    const handleMain = () => {
        navigate('/main');
    };
    const handlePosview = () => {
        navigate('/serchposterpage');
    };
    const handleMusview = () => {
        navigate('/serchmuseumpage');
    };
    const handleCommuview = () => {
        navigate('/communitymainpage');
    };

    return (
        <div className="crepeomainframe">
            <div className="crepeomainform">
                <div className="crepeomainformbg" />
                <div className="crepeomaintxt">회원가입</div>
                <div className="crepeoidtxt">아이디 입력</div>
                <div className="crepeoidbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <input
                            type="text"
                            className="crepeopnumbuttontxt"
                            placeholder="ID"
                            value={cpId}
                            onChange={(e) => setcpId(e.target.value)}
                        />
                    </div>
                </div>
                <div className="crepeopwtxt">비밀번호 입력</div>
                <div className="crepeopwbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <input
                            type="password"
                            className="crepeopnumbuttontxt"
                            placeholder="PW"
                            value={cpPw}
                            onChange={(e) => setcpPw(e.target.value)}
                        />
                    </div>
                </div>
                <div className="crepeopwctxt">비밀번호 확인</div>
                <div className="crepeopwcbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <input
                            type="password"
                            className="crepeopnumbuttontxt"
                            placeholder="PW check"
                            value={cpPwCheck}
                            onChange={(e) => setcpPwCheck(e.target.value)}
                        />
                    </div>
                </div>
                <div className="crepeonametxt">이름 입력</div>
                <div className="crepeonamebutton">
                    <div className="crepeoconfirmbuttonframe">
                        <input
                            type="text"
                            className="crepeopnumbuttontxt"
                            placeholder="Name"
                            value={cpName}
                            onChange={(e) => setcpName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="crepeobirthtxt">생년월일 입력</div>
                <div className="crepeobirthbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <div className="crepeoconfirmbuttonframe">
                            <input
                                type="date"
                                className="crepeopnumbuttontxt"
                                placeholder="BirthDate"
                                value={cpBDate}
                                onChange={(e) => setcpBDate(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="crepeopnumtxt">휴대폰 번호</div>
                <div className="crepeopnumbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <input
                            type="number"
                            min="0100000000"
                            max="05079999999"
                            className="crepeopnumbuttontxt"
                            placeholder="Phone Number"
                            value={cpPNum}
                            onChange={(e) => setcpPNum(e.target.value)}
                        />
                    </div>
                </div>
                <div className="crepeoconfirmbutton" onClick={cpSubmit}>
                    <div className="crepeoconfirmbuttonframe">
                        <div className="crepeoconfirmbuttontxt">회원가입 완료</div>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <div className="navlink">
                    <div className="navlinkhome" onClick={handleMain}>
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkPosview" onClick={handlePosview}>
                        <div className="Posviewhome">공연 기반 정보</div>
                    </div>
                    <div className="navlinkMusview" onClick={handleMusview}>
                        <div className="Musviewhome">공연장 기반 정보</div>
                    </div>
                    <div className="navlinkCommuview" onClick={handleCommuview}>
                        <div className="Commuhome">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePeople;
