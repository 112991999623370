import './DeletePeoplePage.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const DeletePeoplePage = () => {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');

    const handleDelpeople = async () => {
        try {
            const response = await axios.delete(`http://co.mayeon.kr:8081/user/${userId}`);
            console.log('삭제: ', response.data);
            localStorage.clear();
            sessionStorage.clear();
        } catch (error) {
            console.error('Error fetching login data:', error);
        }
        navigate('/deletepeopleafterpage');
    };

    const handleMain = () => {
        navigate('/main');
    };
    const handlePosview = () => {
        navigate('/serchposterpage');
    };
    const handleMusview = () => {
        navigate('/serchmuseumpage');
    };
    const handleCommuview = () => {
        navigate('/communitymainpage');
    };

    return localStorage.getItem('token') || sessionStorage.getItem('token') ? (
        <div className="deletepeoplemainframe">
            <div className="deletepeoplesubframe">
                <div className="deletepeoplesubtxt">
                    <p className="p">커뮤니티 글쓰기 기능</p>
                    <p className="p">커뮤니티 댓글 쓰기 기능</p>
                    <p className="p">&nbsp;</p>
                    <p className="p">그 외 여러가지....</p>
                </div>
                <div className="deletepeoplemaintxt2">탈퇴 후엔 아래 기능을 사용할 수 없게 됩니다!</div>
                <div className="deletepeoplemaintxt1">정말 가시려고요?</div>
                <div className="deletepeoplebutton" onClick={handleDelpeople}>
                    <div className="deletepeoplebuttonframe">
                        <b className="deletepeoplebuttontxt">탈퇴</b>
                    </div>
                </div>
                <div className="deletepeopleignorebutton" onClick={handleMain}>
                    <div className="deletepeoplebuttonframe">
                        <div className="deletepeoplebuttontxt">탈퇴취소</div>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <div className="navlink">
                    <div className="navlinkhome" onClick={handleMain}>
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkPosview" onClick={handlePosview}>
                        <div className="Posviewhome">공연 기반 정보</div>
                    </div>
                    <div className="navlinkMusview" onClick={handleMusview}>
                        <div className="Musviewhome">공연장 기반 정보</div>
                    </div>
                    <div className="navlinkCommuview" onClick={handleCommuview}>
                        <div className="Commuhome">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        window.location.replace('/login')
    );
};

export default DeletePeoplePage;
