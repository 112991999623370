import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CommunityMakePage.css';
import NavHeader from '../../components/header';

function CommunityMakePage() {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handleSubmit = async () => {
        if (!title || !content) {
            alert('제목과 내용을 모두 입력해주세요!');
            return;
        }

        const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');
        if (!userId) {
            alert('로그인이 필요합니다.');
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(
                `http://co.mayeon.kr:8081/api/community?userId=${userId}`,
                // console.log('유저 아이디', userId),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ title, content }),
                }
            );

            if (response.ok) {
                alert('게시글이 작성되었습니다!');
                navigate('/communitymainpage');
            } else {
                alert('게시글 작성에 실패했습니다.');
            }
        } catch (error) {
            console.error('게시글 작성 중 오류 발생:', error);
            alert('서버와의 통신 중 문제가 발생했습니다.');
        }
    };

    const handleCancel = () => {
        navigate('/communitymainpage');
    };

    return (
        <div className="commakepageframe">
            <NavHeader />

            <div className="commakeform">
                <label>
                    제목 :
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                </label>
                <label>
                    내용 :<textarea value={content} onChange={(e) => setContent(e.target.value)}></textarea>
                </label>
                <div className="commakeform-buttons">
                    <button onClick={handleSubmit}>작성</button>
                    <button onClick={handleCancel}>취소</button>
                </div>
            </div>
        </div>
    );
}

export default CommunityMakePage;
