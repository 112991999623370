import './SerchNonePage.css';
import arrowdropdown from '../../img/arrowdropdown.svg';
import cross from '../../img/cross.svg';
import NavHeader from '../../components/header';

const SerchNonePage = () => {
    return (
        <div className="serposnonmainframe">
            <div className="serposnontxtframe">
                <div className="serposnontxt2">
                    <p className="null">우리 트래픽이 가져오려 하였으나 NULL밖에 없다네요!</p>
                    <p className="null">키워드나 지역을 다시 설정하고 시도해주세요!</p>
                </div>
                <div className="serposnontxt1">앗! 검색 내용이 없어요!</div>
            </div>

            <div className="serchposterbar">
                <div className="serposstringserch">
                    <div className="serposstringtxt">꽃놀이</div>
                    <img className="serposstringcancel-icon" alt="" src={cross} />
                </div>
                <div className="serposlistnumgroup">
                    <div className="serposlistnumserch">
                        <div className="serposstringtxt">10</div>
                    </div>
                    <img className="serposlistnumarrow-icon" alt="" src={arrowdropdown} />
                </div>
                <div className="serpossigroup">
                    <div className="serpossiserch">
                        <div className="serposstringtxt">시/도</div>
                    </div>
                    <img className="serpossiarrow-icon" alt="" src={arrowdropdown} />
                </div>
                <div className="serposgugroup">
                    <div className="serpossiserch">
                        <div className="serposstringtxt">군/구</div>
                    </div>
                    <img className="serposguarrow-icon" alt="" src={arrowdropdown} />
                </div>
                <div className="serposgengroup">
                    <div className="serpossiserch">
                        <div className="serposstringtxt">공연</div>
                    </div>
                    <img className="serposguarrow-icon" alt="" src={arrowdropdown} />
                </div>
                <div className="serposprofitbutton">
                    <div className="serposprofitbuttonframe">
                        <b className="serposprofitbuttontxt">검색</b>
                    </div>
                </div>
            </div>
            <NavHeader />
        </div>
    );
};

export default SerchNonePage;
