import './MyListPage.css';
//import image1 from '../../img/image1.png';
//import image2 from '../../img/image2.png';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import NavHeader from '../../components/header';

const MyListPage = () => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [isEmptyList, setisEmptyList] = useState([]);
    const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');

    useEffect(() => {
        const fetchList = async () => {
            try {
                const response = await fetch(`http://co.mayeon.kr:8081/stored-culture/${userId}`);
                const data = await response.json();
                if (data.length === 0) {
                    navigate('/mylistnothavepage');
                } else {
                    setList(data);
                }
            } catch (error) {
                console.error('Error fetching the list', error);
            }
        };
        fetchList();
    }, [userId, navigate]);

    const handleDetails = async (mt20id) => {
        try {
            navigate(`/serchdetailpage/${mt20id}`);
        } catch (error) {
            console.error('Error fetching the details', error);
        }
    };

    const handleMyListEdit = () => {
        navigate('/mylisteditpage');
    };

    return localStorage.getItem('token') || sessionStorage.getItem('token') ? (
        <div className="mylistframe">
            <div className="mylistmodifybutton" onClick={handleMyListEdit}>
                <div className="mylistmodifybuttonframe">
                    <b className="mylistmodifybuttontxt">목록 수정하기</b>
                </div>
            </div>
            <div className="mylist-container">
                {list.map((item, index) => (
                    <div key={index} className="mylistframe1">
                        <img className="mylistframeimg1-icon" alt={item.prfnm} src={item.poster} />
                        <div className="mylistframetxt1">{item.prfnm}</div>
                        <div className="mylistframe1button" onClick={() => handleDetails(item.mt20id)}>
                            <div className="mylistmodifybuttonframe">
                                <b className="mylistmodifybuttontxt">상세보기</b>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/*
                <div className="mylistframe1">
                <img className="mylistframeimg1-icon" alt="" src={image1} />
                <div className="mylistframetxt1">해치마당 미디어월</div>
                <div className="mylistframe1button">
                    <div className="mylistmodifybuttonframe">
                        <b className="mylistmodifybuttontxt">상세보기</b>
                    </div>
                </div>
            </div>
            <div className="mylistframe2">
                <img className="mylistframeimg2-icon" alt="" src={image2} />
                <div className="mylistframetxt1">광장으로의 초대</div>
                <div className="mylistframe1button">
                    <div className="mylistmodifybuttonframe">
                        <b className="mylistmodifybuttontxt">상세보기</b>
                    </div>
                </div>
            </div>
                */}

            <NavHeader />
        </div>
    ) : (
        window.location.replace('/login')
    );
};

export default MyListPage;
