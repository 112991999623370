import './SerchDetailPage.css';
import navback from '../../img/navback.svg';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NavHeader from '../../components/header';
import axios from 'axios';

const SerchDetailPage = () => {
    const { performanceId } = useParams();
    const [performanceData, setPerformanceData] = useState(null);
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');

    useEffect(() => {
        const fetchPerformanceDetail = async () => {
            try {
                const response = await fetch(`http://co.mayeon.kr:8081/culture/performance/detail/${performanceId}`);
                const data = await response.json();
                setPerformanceData(data.culture || {});
                console.log('dfdfdf', data);
            } catch (error) {
                console.error('Error fetching performance detail:', error);
            }
        };

        fetchPerformanceDetail();
    }, [performanceId]);

    const handleViewAmenities = () => {
        navigate(`/serchdetailmappage/${performanceId}`);
    };

    if (!performanceData) {
        return <div>Loading...</div>;
    }

    const handleMyListStore = async () => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (!token) {
            alert('로그인 후 이용해 주세요.');
            navigate('/login');
        } else {
            try {
                const response = await axios.post('http://co.mayeon.kr:8081/stored-culture', {
                    userId: userId,
                    performanceId: performanceId,
                });
                console.log(response); // 응답 확인을 위한 콘솔 로그

                if (response.data) {
                    alert('찜하기가 완료되었습니다.');
                } else {
                    alert('찜하기에 실패했습니다. 이미 찜한 공연인지 확인해보시길 바랍니다.');
                }
            } catch (error) {
                console.error('Error saving to my list:', error);
                alert('찜하기에 실패했습니다. 이미 찜한 공연인지 확인해보시길 바랍니다.');
            }
        }
    };

    return (
        <div className="serdetpermainframe">
            <div className="serdetpersubframe">
                <img className="serdetperimg-icon" alt="poster" src={performanceData.poster || navback} />
                <div className="serdetperdesframe">
                    <b className="serdetpertitletxt">{performanceData.prfnm || '공연명 없음'}</b>
                    <div className="serdetperperiodtxt">{`기간: ${performanceData.prfpdfrom || '정보 없음'} - ${
                        performanceData.prfpdto || '정보 없음'
                    }`}</div>
                    <div className="serdetperdestxt">{`위치: ${performanceData.fcltynm || '위치 정보 없음'}`}</div>
                    <div className="serdetpergenretxt">{`장르: ${performanceData.genrenm || '장르 정보 없음'}`}</div>
                    <div className="serdetperscaletxt">{`규모: ${performanceData.prstate || '정보 없음'}`}</div>
                    <div className="serdetperpnumtxt">{`전화: ${performanceData.telno || '전화 정보 없음'}`}</div>
                    <div className="serdetperruntimetxt">{`공연 런타임: ${
                        performanceData.prfruntime || '정보 없음'
                    }`}</div>
                    <div className="serdetperagetxt">{`관람 연령: ${performanceData.prfage || '정보 없음'}`}</div>
                    <div className="serdetperpricetxt">{`티켓가격: ${
                        performanceData.pcseguidance || '정보 없음'
                    }`}</div>
                    <div className="serdetperstorytxt">{`줄거리: ${performanceData.story || '줄거리 정보 없음'}`}</div>
                    <div className="serdetperdesalign" />
                </div>
            </div>
            <div className="serdetperbuttonframe">
                <div className="serdetperfecbutton" onClick={handleViewAmenities}>
                    <div className="serdetperfecbuttonframe">
                        <b className="serdetperfecbuttontxt">주변 편의시설 조회</b>
                    </div>
                </div>
                <div className="serdetpersavebutton" onClick={handleMyListStore}>
                    <div className="serdetperfecbuttonframe">
                        <b className="serdetperfecbuttontxt">찜하기</b>
                    </div>
                </div>
            </div>
            <NavHeader />
        </div>
    );
};

export default SerchDetailPage;
