import './SerchMuseumDetailPage.css';
import pinmuseum from '../../img/pinmuseum.png';
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NavHeader from '../../components/header';

const SerchMuseumDetailPage = () => {
    const { facilityId } = useParams();
    const [facilityData, setFacilityData] = useState(null);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFacilityDetail = async () => {
            try {
                const response = await fetch(`http://co.mayeon.kr:8081/culture/facility/detail/${facilityId}`);
                const data = await response.json();
                setFacilityData(data);
            } catch (error) {
                console.error('Error fetching facility detail:', error);
            }
        };

        fetchFacilityDetail();
    }, [facilityId]);

    useEffect(() => {
        if (!facilityData || !facilityData.la || !facilityData.lo) return;

        const options = {
            center: new window.kakao.maps.LatLng(facilityData.la, facilityData.lo),
            level: 3,
        };

        map.current = new window.kakao.maps.Map(mapContainer.current, options);

        const markerImage = new window.kakao.maps.MarkerImage(pinmuseum, new window.kakao.maps.Size(30, 45));

        const markerPosition = new window.kakao.maps.LatLng(facilityData.la, facilityData.lo);
        const marker = new window.kakao.maps.Marker({
            position: markerPosition,
            map: map.current,
            image: markerImage,
        });

        const infowindow = new window.kakao.maps.InfoWindow({
            content: `<div style="padding:10px;font-size:14px;line-height:1.5;">
                        <b>${facilityData.fcltynm || '시설명 없음'}</b><br />
                        ${facilityData.adres || '주소 정보 없음'}
                      </div>`,
        });

        infowindow.open(map.current, marker);
    }, [facilityData]);

    const handleViewPerformances = () => {
        navigate(`/serchmuseumperformpage/${facilityId}`);
    };

    if (!facilityData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="sermusdetmainframe">
            <div className="sermusdetmapframe">
                <div
                    ref={mapContainer}
                    style={{
                        width: '86.331rem',
                        height: '52.188rem',
                        borderRadius: '10px',
                        margin: 'auto',
                    }}
                ></div>
            </div>
            <div className="sermusdettxtframe">
                <b className="sermusdetnametxt">{facilityData.fcltynm || '시설명 없음'}</b>
                <div className="sermusdetpnumtxt">전화 : {facilityData.telno || '전화 정보 없음'}</div>
                <div className="sermusdetdestxt">{`위치 : ${facilityData.adres || '위치 정보 없음'}`}</div>
                <div className="sermusdetseatstxt">좌석규모 : {facilityData.seatcnt || '좌석 정보 없음'}</div>
                <div className="sermusdetalignline" />
                <div className="sermusdetperbutton" onClick={handleViewPerformances}>
                    <div className="sermusdetperbuttonframe">
                        <b className="sermusdetperbuttontxt">공연 조회</b>
                    </div>
                </div>
            </div>
            <NavHeader />
        </div>
    );
};

export default SerchMuseumDetailPage;
