import './Mypage.css';
import { useNavigate } from 'react-router-dom';

const Mypage = () => {
    const navigate = useNavigate();
    const username = localStorage.getItem('username') || sessionStorage.getItem('username');

    const handleMyList = () => {
        navigate('/mylistpage');
    };
    const handleRPPage = () => {
        navigate('/rebuildpeople');
    };
    const handleExitP = () => {
        navigate('/deletepeoplepage');
    };

    const handleMain = () => {
        navigate('/main');
    };
    const handlePosview = () => {
        navigate('/serchposterpage');
    };
    const handleMusview = () => {
        navigate('/serchmuseumpage');
    };
    const handleCommuview = () => {
        navigate('/communitymainpage');
    };

    const handleLogout = () => {
        navigate('/communitymainpage');
    };

    return localStorage.getItem('token') || sessionStorage.getItem('token') ? (
        <div className="mypageframe">
            <div className="mypagemainframe">
                <div className="mypagemaintxt">마이페이지</div>
                <div className="mypageinputgroup">
                    <div className="mypageframebg" />
                    <div className="mypagesubtxt">안녕하세요! {username}님!</div>
                    <div className="mypagelistbutton" onClick={handleMyList}>
                        <div className="mypagelistbuttonframe">
                            <div className="mypagelistbuttontxt">찜한 목록 조회</div>
                        </div>
                    </div>
                    <div className="mypagemodifybutton" onClick={handleRPPage}>
                        <div className="mypagelistbuttonframe">
                            <div className="mypagelistbuttontxt">개인정보 수정</div>
                        </div>
                    </div>
                    <div className="mypageexitbutton" onClick={handleExitP}>
                        <div className="mypagelistbuttonframe">
                            <div className="mypagelistbuttontxt">회원탈퇴</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <div className="navlink">
                    <div className="navlinkhome" onClick={handleMain}>
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkPosview" onClick={handlePosview}>
                        <div className="Posviewhome">공연 기반 정보</div>
                    </div>
                    <div className="navlinkMusview" onClick={handleMusview}>
                        <div className="Musviewhome">공연장 기반 정보</div>
                    </div>
                    <div className="navlinkCommuview" onClick={handleCommuview}>
                        <div className="Commuhome">커뮤니티</div>
                    </div>
                </div>
                <b className="logoutbutton" onClick={handleLogout}>
                    로그아웃
                </b>
            </div>
        </div>
    ) : (
        window.location.replace('/login')
    );
};

export default Mypage;
