import './CreatePeopleAfter.css';

const CreatePeopleAfter = () => {
    const create = () => {
        setTimeout(() => {
            window.location.replace('/main');
        }, 1000);
    };

    return (
        <div className="crepeomainafterframe">
            <div className="crepeoafterframe">
                <div className="crepeoaftermaintxt3">메인으로 돌아가는 중.....</div>
                <div className="crepeoaftermaintxt2">안녕하세요! 반갑습니다!</div>
                <div className="crepeoaftermaintxt1">회원가입이 완료되었습니다!</div>
                {create()}
            </div>
            <div className="navbar" />
        </div>
    );
};

export default CreatePeopleAfter;
