import './SaveDetailPage.css';

const SaveDetailPage = () => {
    return (
        <div className="savedetailmainframe">
            <div className="savadetailtxtframe">
                <div className="savedetailtxt2">보던 페이지로 돌아가는 중.....</div>
                <div className="savedetailtxt1">목록에 저장이 완료되었습니다!</div>
            </div>
            <div className="navbar" />
        </div>
    );
};

export default SaveDetailPage;
