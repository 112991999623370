import './SerchMuseumPage.css';
import pinmuseum from '../../img/pinmuseum.png';
// import arrowdropdown from '../../img/arrowdropdown.svg';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import NavHeader from '../../components/header';

const SerchMuseumPage = () => {
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markers = useRef([]);

    const navigate = useNavigate();

    useEffect(() => {
        const loadKakaoMaps = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = '//dapi.kakao.com/v2/maps/sdk.js?appkey=e55ce2f428ca6e286c849083454b41cf';
            document.head.appendChild(script);

            script.addEventListener('load', () => {
                window.kakao.maps.load(() => {
                    const options = {
                        center: new window.kakao.maps.LatLng(37.5665, 126.978), // 서울 중심 좌표
                        level: 9,
                    };
                    map.current = new window.kakao.maps.Map(mapContainer.current, options);
                });
            });
        };

        loadKakaoMaps();
    }, []);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await fetch('http://co.mayeon.kr:8081/culture/code/city');
                const data = await response.json();
                setCities(data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };
        fetchCities();
    }, []);

    useEffect(() => {
        if (selectedCity) {
            const fetchDistricts = async () => {
                try {
                    const response = await fetch(`http://co.mayeon.kr:8081/culture/code/district/${selectedCity}`);
                    const data = await response.json();
                    setDistricts(data);
                } catch (error) {
                    console.error('Error fetching districts:', error);
                }
            };
            fetchDistricts();
        } else {
            setDistricts([]);
        }
    }, [selectedCity]);

    const clearMarkers = () => {
        markers.current.forEach((marker) => marker.setMap(null));
        markers.current = [];
    };

    const addMarkers = (facilities) => {
        if (!map.current || !facilities.length) return;

        clearMarkers();

        const bounds = new window.kakao.maps.LatLngBounds();

        facilities.forEach((facility) => {
            if (facility.la && facility.lo) {
                const markerImage = new window.kakao.maps.MarkerImage(pinmuseum, new window.kakao.maps.Size(30, 45));

                const position = new window.kakao.maps.LatLng(facility.la, facility.lo);
                const marker = new window.kakao.maps.Marker({
                    position: position,
                    map: map.current,
                    image: markerImage,
                    title: facility.fcltynm,
                });

                window.kakao.maps.event.addListener(marker, 'click', () => {
                    handleDetailClick(facility.mt10id);
                });

                markers.current.push(marker);
                bounds.extend(position);
            } else {
                console.log('Invalid coordinates for facility:', facility.fcltynm);
            }
        });

        if (markers.current.length > 0) {
            map.current.setBounds(bounds);
        }
    };

    const updateMapCenter = (latitude, longitude) => {
        if (map.current) {
            const newCenter = new window.kakao.maps.LatLng(latitude, longitude);
            map.current.setCenter(newCenter);
        }
    };

    const fetchFacilities = async () => {
        try {
            const params = new URLSearchParams({
                cpage: '1',
                rows: '100',
                signgucode: selectedCity,
            });

            if (selectedDistrict) {
                params.append('signgucodesub', selectedDistrict);
            }

            const response = await fetch(`http://co.mayeon.kr:8081/culture/facility/list?${params.toString()}`);
            const facilityList = await response.json();

            if (!facilityList || facilityList.length === 0) {
                console.log('No facilities found');
                setFacilities([]);
                clearMarkers();
                return;
            }

            const detailedFacilities = await Promise.all(
                facilityList.map(async (facility) => {
                    try {
                        const detailResponse = await fetch(
                            `http://co.mayeon.kr:8081/culture/facility/detail/${facility.mt10id}`
                        );
                        const detailData = await detailResponse.json();
                        return {
                            ...facility,
                            la: detailData.la,
                            lo: detailData.lo,
                        };
                    } catch (error) {
                        console.error(`Error fetching details for mt10id: ${facility.mt10id}`, error);
                        return facility;
                    }
                })
            );

            setFacilities(detailedFacilities);
            addMarkers(detailedFacilities);
        } catch (error) {
            console.error('Error fetching facilities:', error);
            setFacilities([]);
            clearMarkers();
        }
    };

    const handleSearch = () => {
        fetchFacilities();
    };

    const handleDetailClick = (facilityId) => {
        navigate(`/serchmuseumdetailpage/${facilityId}`);
    };

    const renderFacilities = () => {
        if (!facilities || facilities.length === 0) {
            return <div className="no-results">공연장이 없습니다.</div>;
        }

        return facilities.map((facility, index) => {
            const topPosition = 3.688 + index * 10; // 유지
            return (
                <div key={index} className="sermusres1frame" style={{ top: `${topPosition}rem` }}>
                    <div className="sermusres1title">{facility.fcltynm}</div>
                    <div className="sermusres1detbutton" onClick={() => handleDetailClick(facility.mt10id)}>
                        <div className="sermusres1detbuttonframe">
                            <b className="sermusres1detbuttontxt">상세보기</b>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="sermusmainframe">
            <div className="sermusresmainframe">{renderFacilities()}</div>
            <div className="sermusmapframe">
                <div
                    ref={mapContainer}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            </div>
            <div className="serchmusbar">
                <div className="sermussigroup">
                    <select
                        className="sermussiserch"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                    >
                        <option value="">시/도 선택</option>
                        {cities.map((city) => (
                            <option key={city.code} value={city.code}>
                                {city.name}
                            </option>
                        ))}
                    </select>
                    {/* <img className="sermussiarrow-icon" alt="" src={arrowdropdown} /> */}
                </div>
                <div className="sermusgugroup">
                    <select
                        className="sermusguserch"
                        value={selectedDistrict}
                        onChange={(e) => setSelectedDistrict(e.target.value)}
                        disabled={!selectedCity}
                    >
                        <option value="">군/구 선택</option>
                        {districts.map((district) => (
                            <option key={district.code} value={district.code}>
                                {district.name}
                            </option>
                        ))}
                    </select>
                    {/* <img className="sermusguarrow-icon" alt="" src={arrowdropdown} /> */}
                </div>
                <div className="sermusmainbutton" onClick={handleSearch}>
                    검색
                </div>
            </div>
            <NavHeader />
        </div>
    );
};

export default SerchMuseumPage;
