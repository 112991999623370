import './MyListNotHavePage.css';
import { useNavigate } from 'react-router-dom';

const MyListNotHavePage = () => {
    const navigate = useNavigate();

    const handleMain = () => {
        navigate('/main');
    };
    const handlePosview = () => {
        navigate('/serchposterpage');
    };
    const handleMusview = () => {
        navigate('/serchmuseumpage');
    };
    const handleCommuview = () => {
        navigate('/communitymainpage');
    };
    const handleMypage = () => {
        navigate('/mypage');
    };

    return (
        <div className="mylistnothavepageframe">
            <div className="mylistnothavepagemainframe">
                <div className="mylistnothavepagemaintxt">앗! 목록이 비었어요!</div>
                <div className="mylistnothavepagesubtxt">
                    <p className="null">우리 트래픽이 가져오려 하였으나 NULL밖에 없다네요!</p>
                    <p className="null">목록을 채우고 다시 시도해주세요!</p>
                </div>
                <div className="mylistnothavepagegotomypagebut" onClick={handleMypage}>
                    <div className="mylistnothavepagegotomypagebut1">
                        <div className="mylistnothavepagegotomypagebut2">마이페이지로 돌아가기</div>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <div className="navlink">
                    <div className="navlinkhome" onClick={handleMain}>
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkPosview" onClick={handlePosview}>
                        <div className="Posviewhome">공연 기반 정보</div>
                    </div>
                    <div className="navlinkMusview" onClick={handleMusview}>
                        <div className="Musviewhome">공연장 기반 정보</div>
                    </div>
                    <div className="navlinkCommuview" onClick={handleCommuview}>
                        <div className="Commuhome">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyListNotHavePage;
