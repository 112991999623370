import './SerchPosterPage.css';
// import arrowdropdown from '../../img/arrowdropdown.svg';
import cross from '../../img/cross.svg';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavHeader from '../../components/header';

const SerchPosterPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedGenre, setSelectedGenre] = useState('');
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [genres, setGenres] = useState([]);
    const [posters, setPosters] = useState([]);
    const [isEmpty, setisEmpty] = useState(true);

    // 오늘 날짜
    const today = new Date();
    // 년도
    const year = today.getFullYear();
    const nextyear = today.getFullYear() + 1;
    // 월
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    // 일
    const day = today.getDate().toString().padStart(2, '0');
    // yyyymmdd
    const yyyymmddToday = `${year}${month}${day}`;
    const yyyymmddnextyearToday = `${nextyear}${month}${day}`;

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await fetch('http://co.mayeon.kr:8081/culture/code/city');
                const data = await response.json();
                setCities(data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };
        fetchCities();
    }, []);

    useEffect(() => {
        const fetchGenres = async () => {
            try {
                const response = await fetch('http://co.mayeon.kr:8081/culture/code/genre');
                const data = await response.json();
                setGenres(data);
            } catch (error) {
                console.error('Error fetching genres:', error);
            }
        };
        fetchGenres();
    }, []);

    useEffect(() => {
        if (selectedCity) {
            const fetchDistricts = async () => {
                try {
                    const response = await fetch(`http://co.mayeon.kr:8081/culture/code/district/${selectedCity}`);
                    const data = await response.json();
                    setDistricts(data);
                } catch (error) {
                    console.error('Error fetching districts:', error);
                }
            };
            fetchDistricts();
        }
    }, [selectedCity]);

    const fetchPerformances = async () => {
        try {
            const response = await fetch(
                `http://co.mayeon.kr:8081/culture/performance/list?cpage=1&rows=50&stdate=${yyyymmddToday}&eddate=${yyyymmddnextyearToday}&signgucode=${selectedCity}&signgucodesub=${selectedDistrict}&shcate=${selectedGenre}&shprfnm=${searchTerm}`
            );
            const data = await response.json();
            setPosters(data);
            setisEmpty(true);
        } catch (error) {
            setisEmpty(false);
            console.error('Error fetching performances:', error);
        }
    };

    const handleSearch = () => {
        fetchPerformances();
    };

    return (
        <div className="serposmainframe">
            <div className="poster-results">
                {isEmpty ? (
                    posters
                        .filter((poster) => !searchTerm || poster.prfnm.includes(searchTerm))
                        .map((poster, index) => (
                            <div key={index} className="serchposterview">
                                <img className="serposimg-icon" alt="" src={poster.poster} />
                                <b className="serpostit">{poster.prfnm}</b>
                                <b className="serposper">기간: {poster.prfpdto}</b>
                                <b className="serposdes">위치: {poster.fcltynm}</b>

                                <Link to={`/serchdetailpage/${poster.mt20id}`}>
                                    <div className="serposviewdetail5button">
                                        <div className="serposviewdetail5buttonframe">
                                            <b className="serposviewdetail5buttontxt">상세보기</b>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                ) : (
                    <div className="serposnontxtframe">
                        <div className="serposnontxt2">
                            <p className="null">우리 트래픽이 가져오려 하였으나 NULL밖에 없다네요!</p>
                            <p className="null">키워드나 지역을 다시 설정하고 시도해주세요!</p>
                        </div>
                        <div className="serposnontxt1">앗! 검색 내용이 없어요!</div>
                    </div>
                )}
            </div>

            <div className="serchposterbar">
                <div className="serposstringserch">
                    <input
                        type="text"
                        className="serposstringtxt"
                        placeholder="검색어를 입력해주세요"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <img className="serposstringcancel-icon" alt="" src={cross} onClick={() => setSearchTerm('')} />
                </div>

                <div className="serpossigroup">
                    <select
                        className="serpossiserch"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                    >
                        <option value="">시/도 선택</option>
                        {cities.map((city) => (
                            <option key={city.code} value={city.code}>
                                {city.name}
                            </option>
                        ))}
                    </select>
                    {/* <img className="serpossiarrow-icon" alt="" src={arrowdropdown} /> */}
                </div>
                <div className="serposgugroup">
                    <select
                        className="serpossiserch"
                        value={selectedDistrict}
                        onChange={(e) => setSelectedDistrict(e.target.value)}
                        disabled={!selectedCity}
                    >
                        <option value="">군/구 선택</option>
                        {districts.map((district) => (
                            <option key={district.code} value={district.code}>
                                {district.name}
                            </option>
                        ))}
                    </select>
                    {/* <img className="serposguarrow-icon" alt="" src={arrowdropdown} /> */}
                </div>
                <div className="serposgengroup">
                    <select
                        className="serpossiserch"
                        value={selectedGenre}
                        onChange={(e) => setSelectedGenre(e.target.value)}
                    >
                        <option value="">장르 선택</option>
                        {genres.map((genre) => (
                            <option key={genre.code} value={genre.code}>
                                {genre.name}
                            </option>
                        ))}
                    </select>
                    {/* <img className="serposguarrow-icon" alt="" src={arrowdropdown} /> */}
                </div>
                <div className="serposprofitbutton" onClick={handleSearch}>
                    <div className="serposviewdetail5buttonframe">
                        <b className="serposviewdetail5buttontxt">검색</b>
                    </div>
                </div>
            </div>
            <NavHeader />
        </div>
    );
};

export default SerchPosterPage;
