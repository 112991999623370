import navback from '../img/navback.svg';
import { useNavigate } from 'react-router-dom';
import './header.css';

const NavHeader = () => {
    const navigate = useNavigate();

    const handleLoginpage = () => {
        navigate('/login');
    };
    const handleLogout = () => {
        navigate('/logouted');
    };
    const handleMypage = () => {
        navigate('/mypage');
    };

    const handleMain = () => {
        navigate('/main');
    };
    const handlePosview = () => {
        navigate('/serchposterpage');
    };
    const handleMusview = () => {
        navigate('/serchmuseumpage');
    };
    const handleCommuview = () => {
        navigate('/communitymainpage');
    };

    return (
        <div className="navmainframe">
            <div className="navbar">
                {/* <img className="navback-icon" alt="" src={navback} /> */}
                <div className="navlink">
                    <div className="navlinkhome" onClick={handleMain}>
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkPosview" onClick={handlePosview}>
                        <div className="Posviewhome">공연 기반 정보</div>
                    </div>
                    <div className="navlinkMusview" onClick={handleMusview}>
                        <div className="Musviewhome">공연장 기반 정보</div>
                    </div>
                    <div className="navlinkCommuview" onClick={handleCommuview}>
                        <div className="Commuhome">커뮤니티</div>
                    </div>
                </div>
                {localStorage.getItem('token') || sessionStorage.getItem('token') ? (
                    <div>
                        <b className="logoutbutton" onClick={handleLogout}>
                            로그아웃
                        </b>
                        <b className="mypagebutton" onClick={handleMypage}>
                            마이페이지
                        </b>
                    </div>
                ) : (
                    <b className="loginbutton" onClick={handleLoginpage}>
                        로그인/회원가입
                    </b>
                )}
            </div>
        </div>
    );
};

export default NavHeader;
