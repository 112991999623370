import './RebuildPeopleAfter.css';
const RebuildPeopleAfter = () => {
    const rebuild = () => {
        setTimeout(() => {
            window.location.replace('/main');
        }, 1000);
    };

    return (
        <div className="rebpeoaftermainframe">
            <div className="rebpeoafterframe">
                <div className="rebpeoaftertxt2">메인으로 돌아가는 중.....</div>
                <div className="rebpeoaftertxt1">정보 수정이 완료되었습니다!</div>
                {rebuild()}
            </div>
            <div className="navbar" />
        </div>
    );
};

export default RebuildPeopleAfter;
