import './Logouted.css';

const Logouted = () => {
    const logout = () => {
        setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace('/main');
        }, 1500);
    };

    return localStorage.getItem('token') || sessionStorage.getItem('token') ? (
        <div className="logoutedmainframe">
            <div className="logoutedframe">
                <div className="logoutedmaintxt2">이용해주셔서 감사합니다!</div>
                <div className="logoutedmaintxt1">로그아웃 완료!</div>
                {logout()}
            </div>
            <div className="navbar"></div>
        </div>
    ) : (
        window.location.replace('/main')
    );
};

export default Logouted;
