import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './CommunityDetailPage.css';
import NavHeader from '../../components/header';

function CommunityDetailPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [authorName, setAuthorName] = useState('');
    const [currentUserId, setCurrentUserId] = useState('');
    const [isEditing, setIsEditing] = useState(false); // 수정 모드 상태 추가
    const [updatedTitle, setUpdatedTitle] = useState(''); // 수정된 제목
    const [updatedContent, setUpdatedContent] = useState(''); // 수정된 내용

    const queryParams = new URLSearchParams(location.search);
    const communityId = queryParams.get('communityId');

    useEffect(() => {
        const fetchPostAndUser = async () => {
            try {
                const postResponse = await fetch(`http://co.mayeon.kr:8081/api/community/${communityId}`);
                const postData = await postResponse.json();
                setPost(postData);
                setUpdatedTitle(postData.title); // 초기 제목 설정
                setUpdatedContent(postData.content); // 초기 내용 설정
                setComments(Array.isArray(postData.comments) ? postData.comments : []);

                const userResponse = await fetch(`http://co.mayeon.kr:8081/user/${postData.userId}`);
                const userData = await userResponse.json();
                setAuthorName(userData.name);
            } catch (error) {
                console.error('Error fetching post, user, or comments:', error);
            }
        };

        const loggedUserId = localStorage.getItem('userId') || sessionStorage.getItem('userId');
        setCurrentUserId(loggedUserId);

        if (communityId) fetchPostAndUser();
    }, [communityId]);

    const handleAddComment = async () => {
        if (!newComment.trim()) return;

        try {
            const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');
            const response = await fetch(
                `http://co.mayeon.kr:8081/api/comment?userId=${userId}&communityId=${communityId}`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ content: newComment }),
                }
            );

            if (response.ok) {
                const addedComment = await response.json();
                setComments((prev) => [...prev, addedComment]);
                setNewComment('');
            } else {
                alert('댓글 작성에 실패했습니다.');
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const handleEditComment = async (commentId, updatedContent) => {
        if (!updatedContent.trim()) return;

        try {
            const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');
            const response = await fetch(`http://co.mayeon.kr:8081/api/comment/${commentId}?userId=${userId}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ content: updatedContent }),
            });

            if (response.ok) {
                const updatedComment = await response.json();
                setComments((prev) =>
                    prev.map((comment) => (comment.commentId === updatedComment.commentId ? updatedComment : comment))
                );
            } else {
                alert('댓글 수정에 실패했습니다.');
            }
        } catch (error) {
            console.error('Error editing comment:', error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        try {
            const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');
            await fetch(`http://co.mayeon.kr:8081/api/comment/${commentId}?userId=${userId}`, {
                method: 'DELETE',
            });

            setComments((prev) => prev.filter((comment) => comment.commentId !== commentId));
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const handleDeletePost = async () => {
        try {
            const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');
            await fetch(`http://co.mayeon.kr:8081/api/community/${communityId}?userId=${userId}`, {
                method: 'DELETE',
            });
            alert('게시글이 삭제되었습니다.');
            navigate('/communitymainpage');
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    const handleUpdatePost = async () => {
        if (!updatedTitle.trim() || !updatedContent.trim()) return;

        try {
            const userId = localStorage.getItem('userId') || sessionStorage.getItem('userId');
            const response = await fetch(`http://co.mayeon.kr:8081/api/community/${communityId}?userId=${userId}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ title: updatedTitle, content: updatedContent }), // 제목과 내용 모두 수정
            });

            if (response.ok) {
                const updatedPost = await response.json();
                setPost(updatedPost);
                setIsEditing(false); // 수정 모드 종료
            } else {
                alert('게시글 수정에 실패했습니다.');
            }
        } catch (error) {
            console.error('Error updating post:', error);
        }
    };

    return (
        <div className="comdetailpageframe">
            {post ? (
                <div className="comdetailcontent">
                    <div className="comdetailcontent-header">
                        {isEditing ? (
                            <>
                                <input
                                    type="text"
                                    value={updatedTitle}
                                    onChange={(e) => setUpdatedTitle(e.target.value)} // 제목 수정
                                />
                                <textarea
                                    value={updatedContent}
                                    onChange={(e) => setUpdatedContent(e.target.value)} // 내용 수정
                                />
                            </>
                        ) : (
                            <>
                                <h1>{post.title}</h1>
                                <p>작성자: {authorName}</p>
                            </>
                        )}
                    </div>

                    <div className="comdetailcontent-body">
                        {isEditing ? <button onClick={handleUpdatePost}>수정 완료</button> : <p>{post.content}</p>}
                    </div>

                    {currentUserId === String(post.userId) && (
                        <div>
                            {isEditing ? (
                                <button onClick={() => setIsEditing(false)}>취소</button>
                            ) : (
                                <>
                                    <button onClick={() => setIsEditing(true)}>수정</button>
                                    <button onClick={handleDeletePost}>삭제</button>
                                </>
                            )}
                        </div>
                    )}

                    <h2>댓글</h2>
                    <div className="comment-section">
                        {Array.isArray(comments) &&
                            comments.map((comment) => (
                                <div key={comment.commentId} className="comment">
                                    <p>{comment.content}</p>
                                    {currentUserId === String(comment.userId) && (
                                        <div className="comment-actions">
                                            <button onClick={() => handleEditComment(comment.commentId, '수정된 내용')}>
                                                수정
                                            </button>
                                            <button onClick={() => handleDeleteComment(comment.commentId)}>삭제</button>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>

                    <input
                        type="text"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="댓글을 입력하세요"
                    />
                    <button onClick={handleAddComment}>작성</button>
                </div>
            ) : (
                <p>게시글을 찾을 수 없습니다.</p>
            )}
            <NavHeader />
        </div>
    );
}

export default CommunityDetailPage;
