import './App.css';
import { Route, Routes } from 'react-router-dom';
import Main from './page/Main/Main';
import LoginedMain from './page/LoginedMain/LoginedMain';
import Login from './page/Login/Login';
import Mypage from './page/Mypage/Mypage';
import MyListNotHavePage from './page/MyListNotHavePage/MyListNotHavePage';
import MyListPage from './page/MyListPage/MyListPage';
import MyListEditPage from './page/MyListEditPage/MyListEditPage';
import DeletePeoplePage from './page/DeletePeoplePage/DeletePeoplePage';
import DeletePeopleAfterPage from './page/DeletePeopleAfterPage/DeletePeopleAfterPage';
import CommunityMainPage from './page/CommunityMainPage/CommunityMainPage';
import CommunityMakePage from './page/CommunityMakePage/CommunityMakePage';
import CommunityDetailPage from './page/CommunityDetailPage/CommunityDetailPage';
import CreatePeople from './page/CreatePeople/CreatePeople';
import CreatePeopleAfter from './page/CreatePeopleAfter/CreatePeopleAfter';
import RebuildPeople from './page/RebuildPeople/RebuildPeople';
import RebuildPeopleAfter from './page/RebuildPeopleAfter/RebuildPeopleAfter';
import SerchPosterPage from './page/SerchPosterPage/SerchPosterPage';
import SerchMuseumPage from './page/SerchMuseumPage/SerchMuseumPage';
import SerchMuseumDetailPage from './page/SerchMuseumDetailPage/SerchMuseumDetailPage';
import SerchMuseumPerformPage from './page/SerchMuseumPerformPage/SerchMuseumPerformPage';
import SerchNonePage from './page/SerchNonePage/SerchNonePage';
import SerchDetailPage from './page/SerchDetailPage/SerchDetailPage';
import SerchDetailMapPage from './page/SerchDetailMapPage/SerchDetailMapPage';
import SaveDetailPage from './page/SaveDetailPage/SaveDetailPage';
import Logouted from './page/Logouted/Logouted';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/main" element={<Main />} />
            <Route path="/loginedmain" element={<LoginedMain />} />
            <Route path="/login" element={<Login />} />
            <Route path="/mypage" element={<Mypage />} />
            <Route path="/mylistnothavepage" element={<MyListNotHavePage />} />
            <Route path="/mylistpage" element={<MyListPage />} />
            <Route path="/mylisteditpage" element={<MyListEditPage />} />
            <Route path="/deletepeoplepage" element={<DeletePeoplePage />} />
            <Route path="/deletepeopleafterpage" element={<DeletePeopleAfterPage />} />
            <Route path="/communitymainpage" element={<CommunityMainPage />} />
            <Route path="/communitymakepage" element={<CommunityMakePage />} />
            <Route path="/communitydetailpage" element={<CommunityDetailPage />} />
            <Route path="/createpeople" element={<CreatePeople />} />
            <Route path="/createpeopleafter" element={<CreatePeopleAfter />} />
            <Route path="/rebuildpeople" element={<RebuildPeople />} />
            <Route path="/rebuildpeopleafter" element={<RebuildPeopleAfter />} />
            <Route path="/serchposterpage" element={<SerchPosterPage />} />
            <Route path="/serchmuseumpage" element={<SerchMuseumPage />} />
            <Route path="/serchmuseumdetailpage/:facilityId" element={<SerchMuseumDetailPage />} />
            <Route path="/serchmuseumperformpage/:facilityId" element={<SerchMuseumPerformPage />} />
            <Route path="/serchnonepage" element={<SerchNonePage />} />
            <Route path="/serchdetailpage/:performanceId" element={<SerchDetailPage />} />
            <Route path="/serchdetailmappage/:performanceId" element={<SerchDetailMapPage />} />
            <Route path="/savedetailpage" element={<SaveDetailPage />} />
            <Route path="/logouted" element={<Logouted />} />
        </Routes>
    );
}

export default App;
