import './CommunityMainPage.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavHeader from '../../components/header';

const CommunityMainPage = () => {
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch('http://co.mayeon.kr:8081/api/community');
                const data = await response.json();
                if (Array.isArray(data)) {
                    setPosts(data);
                } else {
                    console.error('Data is not an array:', data);
                    setPosts([]);
                }
            } catch (error) {
                console.error('Failed to fetch posts:', error);
                setPosts([]);
            }
        };
        fetchPosts();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() + 9);
        return date.toLocaleDateString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const handleCreate = () => navigate('/communitymakepage');
    const handleDetail = (communityId) => navigate(`/communitydetailpage?communityId=${communityId}`);

    return (
        <div className="commainpageframe">
            <NavHeader />

            <div className="commaintitleframe">
                <div className="commaintitleframetitle">제목</div>
            </div>
            {Array.isArray(posts) &&
                posts.map((post) => (
                    <div
                        key={post.communityId}
                        className="comsubtitleframe"
                        onClick={() => handleDetail(post.communityId)}
                    >
                        <div className="comsubframetitle1">{post.title}</div>
                        <div className="comsubframecreatedate">
                            {post.createAt ? formatDate(post.createAt) : '날짜 정보 없음'}
                        </div>
                    </div>
                ))}
            <div className="commainmakebutton" onClick={handleCreate}>
                글쓰기
            </div>
        </div>
    );
};

export default CommunityMainPage;
