import './RebuildPeople.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RebuildPeople = () => {
    const navigate = useNavigate();

    const rpId = localStorage.getItem('userId') || sessionStorage.getItem('userId');
    const [rpPw, setrpPw] = useState('');
    const [rpPwCheck, setrpPwCheck] = useState('');
    const [rpBDate, setrpBDate] = useState('');
    const [rpPNum, setrpPNum] = useState('');

    const loadPeo = useCallback(async () => {
        try {
            const response = await fetch(`http://co.mayeon.kr:8081/user/${rpId}`);
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();
            setrpBDate(data.birthDate);
            setrpPNum(data.phoneNum);
        } catch (error) {
            console.error('Error loading user data:', error);
            alert('유저 정보를 불러오는 중 오류가 발생했습니다.');
        }
    }, [rpId]);

    useEffect(() => {
        loadPeo();
    }, [loadPeo]);

    const fetchRPData = async () => {
        if (rpPw !== rpPwCheck) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }
        try {
            const response = await fetch('http://co.mayeon.kr:8081/user', {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: rpId,
                    password: rpPw,
                    birthDate: rpBDate,
                    phoneNum: rpPNum,
                }),
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            //const data = await response.json();
            alert('정보수정이 완료되었습니다.');
            navigate('/rebuildpeopleafter');
        } catch (error) {
            console.error('Error during registration:', error);
            alert('정보수정 중 오류가 발생했습니다.');
        }
    };

    const rpSubmit = () => {
        if (window.confirm('위 내용으로 정보를 수정하시겠습니까?')) {
            fetchRPData();
        }
    };

    const handleMain = () => {
        navigate('/main');
    };
    const handlePosview = () => {
        navigate('/serchposterpage');
    };
    const handleMusview = () => {
        navigate('/serchmuseumpage');
    };
    const handleCommuview = () => {
        navigate('/communitymainpage');
    };

    return (
        <div className="rebpeomainframe">
            <div className="rebpeomainform">
                <div className="rebpeomainformbg" />
                <div className="rebpeomaintxt">정보 수정</div>
                <div className="rebpeopwtxt">변경할 비밀번호</div>
                <div className="rebpeopwbutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <input
                            type="password"
                            className="rebpeopnumbuttontxt"
                            placeholder="PW"
                            value={rpPw}
                            onChange={(e) => setrpPw(e.target.value)}
                        />
                    </div>
                </div>
                <div className="rebpeopwctxt">비밀번호 확인</div>
                <div className="rebpeopwcbutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <input
                            type="password"
                            className="rebpeopnumbuttontxt"
                            placeholder="PW check"
                            value={rpPwCheck}
                            onChange={(e) => setrpPwCheck(e.target.value)}
                        />
                    </div>
                </div>
                <div className="rebpeobirthtxt">생년월일 수정</div>
                <div className="rebpeobirthbutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <input
                            type="date"
                            className="rebpeopnumbuttontxt"
                            placeholder="BirthDate"
                            value={rpBDate}
                            onChange={(e) => setrpBDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className="rebpeopnumtxt">휴대폰 번호 수정</div>
                <div className="rebpeopnumbutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <input
                            type="number"
                            min="0100000000"
                            max="05079999999"
                            className="rebpeopnumbuttontxt"
                            placeholder="Phone Number"
                            value={rpPNum}
                            onChange={(e) => setrpPNum(e.target.value)}
                        />
                    </div>
                </div>
                <div className="rebpeoconfirmbutton" onClick={rpSubmit}>
                    <div className="rebpeoconfirmbuttonframe">
                        <div className="rebpeoconfirmbuttontxt">정보 수정 완료</div>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <div className="navlink">
                    <div className="navlinkhome" onClick={handleMain}>
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkPosview" onClick={handlePosview}>
                        <div className="Posviewhome">공연 기반 정보</div>
                    </div>
                    <div className="navlinkMusview" onClick={handleMusview}>
                        <div className="Musviewhome">공연장 기반 정보</div>
                    </div>
                    <div className="navlinkCommuview" onClick={handleCommuview}>
                        <div className="Commuhome">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RebuildPeople;
