import './DeletePeopleAfterPage.css';

const DeletePeopleAfterPage = () => {
    const deletePeo = async () => {
        setTimeout(() => {
            window.location.replace('/main');
        }, 1500);
    };

    return (
        <div className="deletepeopleaftermainframe">
            <div className="deletepeopleaftersubframe">
                <div className="deletepeopleaftermaintxt3">다음번에 다시 만날 수 있길 희망합니다!</div>
                <div className="deletepeopleaftermaintxt2">그동안 이용해주셔서 감사합니다!</div>
                <div className="deletepeopleaftermaintxt1">회원탈퇴 완료!</div>
                {deletePeo()}
            </div>
            <div className="navbar"></div>
        </div>
    );
};

export default DeletePeopleAfterPage;
